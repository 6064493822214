export const allowlistAddresses = [
  ["0xf4560b1ef2b8840bb6666d39e7c8be916c76cf5b",1],
  ["0x50889e629ea47888a38b0b256bb0212183b6a1e9",1],
  ["0x2ddb4231c2107a7666ac828ee4cb09d971e99d8d",1],
  ["0x0a1a1569d5d1e69d2df790f9de59891fe6b59dd9",1],
  ["0x26e71ea32074f01c7c9dbd67a1ec92ae6a950d2b",1],
  ["0xac850b9ee0817a061b79d5e63f94cb9ed5250cac",1],
  ["0x096a29951b2407d981d80b51ccaea8908d5d29bd",1],
  ["0xf7fe090c7a98dc9b3078a0e569ba1d79737ffa35",1],
  ["0xa3edec8f17b4731c03c9c8c5ac32557b8d76d1d4",1],
  ["0x7b005e81744be1a524dc14af1c2c5967f0dd03d9",1],
  ["0x54afd453a90dcb7ef38b25a9e55b49623e575782",1],
  ["0x70dbea0054ea488cd23b05be02c1581d026ac983",1],
  ["0x318b811b59ab9fa6b8adcdd5a5eae70fc5686efa",1],
  
]